import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonButton,
} from "@ionic/react";

interface CardProps {
  question: string;
  questionId: string;
  onYesVote: () => void;
  onNoVote: () => void;
}

const Card = ({ question, questionId, onYesVote, onNoVote }: CardProps) => {
  return (
    <IonCard class="w-1/2">
      <IonCardHeader>
        <IonCardSubtitle>Question</IonCardSubtitle>
        <IonCardTitle>{question}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent class="flex justify-around">
        <IonButton onClick={onYesVote}>Yes</IonButton>
        <IonButton onClick={onNoVote}>No</IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default Card;
