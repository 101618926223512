import { IonPage, IonContent } from "@ionic/react";

const Mine = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="flex flex-col items-center justify-center h-full space-y-3 ">
          <div>Mine</div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Mine;
