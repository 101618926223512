import axios, { AxiosRequestConfig } from "axios";
import { getIdToken, refreshIdToken } from "./firebase";

const backendAxios = axios.create();
backendAxios.interceptors.request.use(
  async (config) => {
    const token = await getIdToken();
    config.headers = {
      ...config.headers,
      ...(token && { Authorization: `Bearer ${token}` }),
    };
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export const login = async (firebaseIdToken: string) => {
  const res = await backendAxios.post(
    `${process.env.REACT_APP_BACKEND_URL}/onboard`,
    null,
    {
      headers: {
        Authorization: `Bearer ${firebaseIdToken}`,
      },
    }
  );
  return res.data;
};

export const fetchRandomQuestions = async () => {
  const res = await backendAxios.get(
    `${process.env.REACT_APP_BACKEND_URL}/random-questions`
  );
  return res.data;
};

export const fetchRandomQuestion = async () => {
  const res = await backendAxios.get(
    `${process.env.REACT_APP_BACKEND_URL}/random-question`
  );
  return res.data;
};

export const fetchOpenStats = async () => {
  const res = await backendAxios.get(
    `${process.env.REACT_APP_BACKEND_URL}/open-stat`
  );
  return res.data;
};

export const fetchQuestionStats = async ({
  questionId,
}: {
  questionId: string;
}) => {
  const res = await backendAxios.get(
    `${process.env.REACT_APP_BACKEND_URL}/question-stats?id=${questionId}`
  );
  return res.data;
};

export const vote = async ({
  questionId,
  vote,
}: {
  questionId: string;
  vote: boolean;
}) => {
  const res = await backendAxios.post(
    `${process.env.REACT_APP_BACKEND_URL}/vote`,
    {
      questionId,
      vote,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return res.data;
};

export const submitQuestion = async (question: string) => {
  const res = await backendAxios.post(
    `${process.env.REACT_APP_BACKEND_URL}/question`,
    {
      question,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return res.data;
};
