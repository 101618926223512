import {
  IonContent,
  IonPage,
  IonRippleEffect,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { useHistory } from "react-router";
import { login } from "../lib/backend";
import {
  getCurrentUser,
  getUserName,
  refreshIdToken,
  signInGoogle,
  signOut,
} from "../lib/firebase";
import { Button } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { firebaseUserAtom, isUserSignedInAtom } from "../store";

const Login: React.FC = () => {
  const history = useHistory();
  const [presentToast, dismissToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();
  const isUserSignedIn = useAtomValue(isUserSignedInAtom);
  const firebaseUser = useAtomValue(firebaseUserAtom);
  // const { firebaseUser, setFirebaseUser, isSignedIn } = useStore();

  const NotLoggedInView = () => (
    <div>
      <h1>Do you?</h1>
      <p>Please sign-in:</p>
      <Button
        onClick={async () => {
          presentLoading("Signing in");
          try {
            const idToken = await signInGoogle();
            await login(idToken as string);
            // refresh to get custom user claim in JWT token
            await refreshIdToken();
          } catch (error) {
            presentToast("error signing in", 3000);
          } finally {
            dismissLoading();
          }
        }}
      >
        Sign In Google
      </Button>
    </div>
  );

  const LoggedInView = () => (
    <div>
      <h1>Do you?</h1>
      <p>Welcome: {firebaseUser?.displayName}</p>
      <Button
        onClick={async () => {
          presentLoading("Signing Out");
          try {
            await signOut();
          } catch (error) {
            presentToast("error signing out", 3000);
          } finally {
            dismissLoading();
          }
        }}
      >
        Sign Out
      </Button>
    </div>
  );

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="flex flex-col items-center justify-center h-full space-y-3">
          {isUserSignedIn ? <LoggedInView /> : <NotLoggedInView />}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
