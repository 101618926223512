import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { share, moon } from "ionicons/icons";
import { useHistory } from "react-router";

const Settings: React.FC = () => {
  let history = useHistory();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => {}}>
              <IonIcon slot="icon-only" icon={share}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonItem lines="full">
            <IonIcon slot="start" icon={moon}></IonIcon>
            <IonLabel>Toggle Dark Theme</IonLabel>
            <IonToggle
              onIonChange={(ev) => {
                document.body.classList.toggle("dark", ev.detail.checked);
              }}
              slot="end"
            ></IonToggle>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
