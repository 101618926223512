import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { create, ellipse, home, logIn, personCircle, square, statsChart, triangle } from "ionicons/icons";
import { Redirect, Route } from "react-router";
import CreateQuestion from "./CreateQuestion";
import Home from "./Home";

import Login from "./Login";
import Mine from "./Mine";
import Settings from "./Settings";
import Stats from "./Stats";

const MainTabs = () => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/tabs" to="/tabs/home" />
        <Route exact path="/tabs/home">
          <Home />
        </Route>
        <Route exact path="/tabs/stats">
          <Stats />
        </Route>
        <Route exact path="/tabs/test">
          <Settings />
        </Route>
        <Route exact path="/tabs/create">
          <CreateQuestion />
        </Route>
        <Route exact path="/tabs/mine">
          <Mine />
        </Route>
        <Route exact path="/tabs/login">
          <Login />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/tabs/home">
          <IonIcon icon={home} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab="stats" href="/tabs/stats">
          <IonIcon icon={statsChart} />
          <IonLabel>Stats</IonLabel>
        </IonTabButton>
        <IonTabButton tab="create" href="/tabs/create">
          <IonIcon icon={create} />
          <IonLabel>Create</IonLabel>
        </IonTabButton>
        <IonTabButton tab="mine" href="/tabs/mine">
          <IonIcon icon={personCircle} />
          <IonLabel>Mine</IonLabel>
        </IonTabButton>
        <IonTabButton tab="login" href="/tabs/login">
          <IonIcon icon={logIn} />
          <IonLabel>Login</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
