import {
  IonPage,
  IonContent,
  IonLoading,
} from "@ionic/react";
import { useMutation, useQuery } from "react-query";
import Card from "../components/Card";
import { fetchRandomQuestion, vote } from "../lib/backend";

const Home = () => {
  const { isLoading, isFetching, error, data, refetch } = useQuery(
    "question",
    fetchRandomQuestion,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { mutate, isLoading: isVoteLoading } = useMutation(vote);

  const castVote = (questionId: string, vote: boolean) => {
    mutate({ questionId, vote });
    refetch();
  };

  let toShow = () => {
    if (isLoading || isFetching || isVoteLoading)
      return <IonLoading isOpen message={"Loading..."} />;

    return (
      <div className="grid place-items-center h-full">
        {error ? (
          <div>Error occured</div>
        ) : (
          <Card
            question={data?.question}
            questionId={data?.id}
            onNoVote={() => {
              castVote(data?.id, false);
            }}
            onYesVote={() => {
              castVote(data?.id, true);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <IonPage>
      <IonContent fullscreen>{toShow()}</IonContent>
    </IonPage>
  );
};

export default Home;
