import { IonContent, IonPage, useIonLoading, useIonToast } from "@ionic/react";
import { Button, TextInput } from "@mantine/core";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { submitQuestion } from "../lib/backend";
import "./CreateQuestion.css";
import { useForm } from "@mantine/form";
import { useAtomValue } from "jotai";
import { isUserSignedInAtom } from "../store";

const CreateQuestion: React.FC = () => {
  const isUserSignedIn = useAtomValue(isUserSignedInAtom);

  const queryClient = useQueryClient();
  const form = useForm({
    initialValues: {
      question: "",
    },
  });

  let history = useHistory();
  const [presentToast, dismissToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();
  const mutation = useMutation(submitQuestion, {
    onSuccess: () => {
      // Invalidate and refetch
      // queryClient.invalidateQueries("questions");
    },
    retry: 1,
  });

  if (!isUserSignedIn) {
    return (
      <IonPage>
        <IonContent fullscreen>
          <div className="flex flex-col items-center justify-center h-full space-y-3">
            <p>Please Sign in to create Question</p>
          </div>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="flex flex-col items-center justify-center h-full space-y-3">
          <form
            onSubmit={form.onSubmit(async (values) => {
              if (!values.question) {
                presentToast("Please fill in question", 3000);
                form.setFieldError("question", <></>);
                return;
              }
              try {
                presentLoading("Creating Question");
                const res = await mutation.mutateAsync(values.question);
                // history.push("home");
                presentToast("Success!", 1000);
                form.setValues({ question: "" });
              } catch (error) {
                presentToast("failed to create question", 3000);
              } finally {
                dismissLoading();
              }
            })}
          >
            <TextInput
              label="Question"
              placeholder="question"
              {...form.getInputProps("question")}
            />
            <Button type="submit">Submit</Button>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CreateQuestion;
