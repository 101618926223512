import { User } from "@capacitor-firebase/authentication";
import { atom } from "jotai";
import { authStateChangeListener, webSignInAnonymously } from "./lib/firebase";


export const firebaseUserAtom = atom<User | null>(null)
export const isUserSignedInAtom = atom((get) => get(firebaseUserAtom)?.isAnonymous === false);

firebaseUserAtom.onMount = (setAtom) => {
    const listenerPromise = authStateChangeListener(async (change) => {
        const user = change.user;
        if (!user) {
            webSignInAnonymously();
        } else {
            setAtom(change.user);
        }
    });
    return () => {
        listenerPromise.then((listener) => {
            listener.remove();
        })
    }
}