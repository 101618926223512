import {
  AuthStateChange,
  FirebaseAuthentication,
} from "@capacitor-firebase/authentication";
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBsE2Gz9g3oOOrEO6Ja7nWSwCrB_PK2CMA",
  authDomain: "do-you-82916.firebaseapp.com",
  projectId: "do-you-82916",
  storageBucket: "do-you-82916.appspot.com",
  messagingSenderId: "937995707827",
  appId: "1:937995707827:web:1afbda643c562137e297c6",
};

initializeApp(firebaseConfig);

export const signInGoogle = async () => {
  const result = await FirebaseAuthentication.signInWithGoogle();
  return result.credential?.idToken;
};

export const webSignInAnonymously = async () => {
  const result = await signInAnonymously(getAuth());
  return await result.user.getIdToken();
};

export const getCurrentUser = async () => {
  const result = await FirebaseAuthentication.getCurrentUser();
  return result.user;
};

export const getIdToken = async () => {
  const result = await FirebaseAuthentication.getIdToken();
  return result.token;
};

export const authStateChangeListener = async (
  callback: (change: AuthStateChange) => void
) => {
  return FirebaseAuthentication.addListener("authStateChange", callback);
};

export const signOut = async () => {
  await FirebaseAuthentication.signOut();
};

export const refreshIdToken = async () =>
  await await (
    await FirebaseAuthentication.getIdToken({ forceRefresh: true })
  ).token;

export const getUserName = async () => {
  return (
    (await FirebaseAuthentication.getCurrentUser()).user?.displayName ?? ""
  );
};
