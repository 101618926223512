import { IonPage, IonContent } from "@ionic/react";
import { useEffect } from "react";
import { useQueryClient, useQuery } from "react-query";
import { useLocation } from "react-router";
import { fetchOpenStats } from "../lib/backend";

const Stats = () => {
  const queryClient = useQueryClient();
  const { isLoading, error, data } = useQuery("open-stats", fetchOpenStats, {
    refetchOnWindowFocus: false,
  });
  let location = useLocation();

  useEffect(() => {
    if(location.pathname === '/tabs/stats'){
      queryClient.invalidateQueries("open-stats");
    }
  }, [location, queryClient]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="flex flex-col items-center justify-center h-full space-y-3 ">
          <div>stats</div>
          <div>Total questions: {data?.totalQuestions ?? "-"}</div>
          <div>Total Answers: {data?.totalAnswers ?? "-"}</div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Stats;
